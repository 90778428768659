<template>
  <auth-layout>
    <template #default>
      <h2 class="auth-title">{{$t('auth.reset_password_page.title')}}</h2>
      <p v-html="$t('auth.reset_password_page.explanation')"/>
      <el-form ref="form" :model="form" @submit.prevent.native="null">
        <!--        <el-form-item :label="$t('auth.reset_login_page.form.input_email.label')">-->
        <!--          <el-input v-model="form.email"></el-input>-->
        <!--        </el-form-item>-->
        <base-input
          :label="$t('auth.sign_in_page.form.input_login.label')"
          ref="login"
          prop="login"
          v-model="form.login"
          :validations="$v.form.login"
          :success="success"
          name="login"
          @keyupEnter="handleSubmit"
        />
        <el-form-item>
          <div class="button-group">
            <a href="#" @click.prevent="$router.push({name: 'Login'})" class="grayed-link">
              {{ $t('general.button_back.label')}}
            </a>
            <el-button type="primary" round class="btn-with-chevron submit-btn" @click.prevent="handleSubmit"
                       :loading="loading">
                <span class="label">{{$t('auth.reset_password_page.form.button_reset.label')}}
                  <i class="ri-arrow-right-s-line"></i>
                </span>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </template>
  </auth-layout>
</template>

<script>
import AuthLayout from '../layouts/AuthLayout'
import BaseInput from '../components/BaseInput'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ResetPassword',
  components: {
    BaseInput,
    AuthLayout
  },
  data () {
    return {
      form: {
        login: ''
      },
      loading: false,
      error: '',
      success: '',
      submitDisabled: false
    }
  },
  validations: {
    form: {
      login: {
        required
      }
    }
  },
  methods: {
    async handleSubmit () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return true
      }
      this.loading = true
      try {
        await this.$api.auth.resetPassword(this.form.login, () => {
          this.success = this.$t('auth.reset_password_page.form.success_message')
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.login.$refs.input.focus()
    })
  }
}
</script>

<style lang="scss">

</style>
